<template>
    <pop-up background-color="rgba(0, 0, 0, 0.7)" :on-close="onCancel">
        <div class="title">切換權限</div>
        <div class="input">
            <div class="text">請選擇權限：</div>
            <el-select v-model="auth" placeholder="Select">
                <el-option
                    v-for="item in authList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
        </div>
        <div class="button">
            <button-wrapper type="modal" color="primary" @click="onConfirm">
                確定
            </button-wrapper>
        </div>
    </pop-up>
</template>

<script>
import PopUp from '@/components/Popup/PopUpWrapper.vue';
import { mapActions } from 'vuex';

export default {
    mixins: [],
    props: {
        disease: String,
        target: Object,
    },
    components: { PopUp },
    data() {
        return {
            auth: undefined,
            authList: [
                {
                    label: 'PJP',
                    value: 'sales',
                },
                {
                    label: '管理者',
                    value: 'manager',
                },
                // {
                //     label: '最高管理者',
                //     value: 'super',
                // },
            ],
        };
    },
    computed: {
        isEditMode() {
            return !!this.target;
        },
    },
    created() {
        this.fetchData();
    },
    methods: {
        ...mapActions({
            apiClient: 'api/invoke',
        }),
        fetchData() {
            if (!this.isEditMode) return;
            this.auth = this.target.auth;
        },
        onCancel() {
            this.$emit('close');
        },
        async onConfirm() {
            const { id, name } = this.target;
            const PAYLOAD = {
                category: 'Account',
                function: 'superChangeAuth',
                data: { disease: this.disease, id, toAuth: this.auth },
                successMsg: `已更變${name}權限`,
            };
            await this.apiClient(PAYLOAD);

            this.$emit('close');
            this.$emit('refresh');
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    color: #000000;
    font-size: 24px;
    line-height: 35px;
}
.input {
    display: flex;
    align-items: center;
    margin: 30px 0;
}
.button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
</style>
