<template>
    <div>
        <section class="operator">
            <el-button @click="$emit('createSales')"> 新增PJP </el-button>
            <el-button @click="$emit('createManager')"> 新增管理者 </el-button>
            <el-button
                :disabled="!isSelectionChecked"
                @click="onBulkDeactivate"
            >
                停用
            </el-button>
            <el-button
                :disabled="!isSelectionChecked"
                @click="onBulkRenewToken"
            >
                Renew-Token
            </el-button>
        </section>
        <el-table
            empty-text="暫無資料"
            :data="accountList"
            height="550"
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="name" label="姓名" width="180">
            </el-table-column>
            <el-table-column
                prop="email"
                label="Email"
                width="320"
            ></el-table-column>
            <el-table-column label="權限" width="150">
                <template slot-scope="scope">
                    {{ displayAuthLabel(scope.row.auth) }}
                </template>
            </el-table-column>
            <el-table-column>
                <template />
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="280">
                <template slot-scope="scope">
                    <el-button
                        @click.native.prevent="onEdit(scope.row)"
                        type="text"
                    >
                        編輯
                    </el-button>
                    <el-button
                        @click.native.prevent="onDeactivate(scope.row.id)"
                        type="text"
                    >
                        停用
                    </el-button>
                    <el-button
                        @click.native.prevent="onRenewToken(scope.row.id)"
                        type="text"
                    >
                        Renew-Token
                    </el-button>
                    <el-button
                        @click.native.prevent="onToggle(scope.row)"
                        type="text"
                    >
                        更變權限
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';

export default {
    props: {
        disease: String,
    },
    data() {
        return {
            multipleSelection: [],
            accountList: [],
        };
    },
    watch: {
        disease() {
            this.getAllSales();
        },
    },
    computed: {
        isSelectionChecked() {
            return this.multipleSelection.length !== 0;
        },
    },
    created() {
        this.getAllSales();
    },
    methods: {
        ...mapActions({
            apiClient: 'api/invoke',
        }),

        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        async getAllSales() {
            this.accountList = [];
            const PAYLOAD = {
                category: 'Account',
                function: 'superSearchSales',
                data: this.disease,
            };
            const RES = await this.apiClient(PAYLOAD);
            RES.data.forEach((item) => {
                const AUTH = _.find(item.AccountAuths, {
                    DiseaseId: this.disease,
                }).auth;
                const DATA = {
                    ...item,
                    auth: AUTH,
                };
                this.accountList.push(DATA);
            });
        },
        async onToggle(target) {
            this.$emit('open', target, 'change');
        },
        onEdit(target) {
            if (target.auth === 'sales') {
                this.$emit('open', target, target.auth);
            } else this.$emit('open', target, target.auth);
        },
        async onRenewToken(id) {
            const PAYLOAD = {
                category: 'Account',
                function: 'superRenewToken',
                successMsg: '帳號已Renew Token',
                data: { disease: this.disease, ids: [id] },
            };
            await this.apiClient(PAYLOAD);
            this.getAllSales();
        },
        async onDeactivate(id) {
            const PAYLOAD = {
                category: 'Account',
                function: 'superDeactivate',
                successMsg: '帳號已停用',
                data: { disease: this.disease, ids: [id] },
            };
            await this.apiClient(PAYLOAD);
            this.getAllSales();
        },
        async onBulkRenewToken() {
            const IDS = [];
            this.multipleSelection.forEach((item) => {
                IDS.push(item.id);
            });
            const PAYLOAD = {
                category: 'Account',
                function: 'superRenewToken',
                successMsg: '帳號已Renew Token',
                data: { disease: this.disease, ids: IDS },
            };
            await this.apiClient(PAYLOAD);
            this.getAllSales();
        },
        async onBulkDeactivate() {
            const IDS = [];
            this.multipleSelection.forEach((item) => {
                IDS.push(item.id);
            });
            const PAYLOAD = {
                category: 'Account',
                function: 'superDeactivate',
                successMsg: '帳號已停用',
                data: { disease: this.disease, ids: IDS },
            };
            await this.apiClient(PAYLOAD);
            this.getAllSales();
        },
        displayAuthLabel(auth) {
            switch (auth) {
                case 'super':
                    return '最高管理者';
                case 'manager':
                    return '管理者';
                default:
                    return 'PJP';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.operator {
    margin-bottom: 10px;
}
</style>
