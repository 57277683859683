<template>
    <div class="super-console">
        <section class="select-disease">
            <div class="label">Disease：</div>
            <el-select v-model="disease" placeholder="Select">
                <el-option
                    v-for="item in diseaseList"
                    :key="item.id"
                    :label="item.id"
                    :value="item.id"
                >
                </el-option>
            </el-select>
        </section>
        <section class="account-table">
            <AccountTable
                ref="accountTable"
                v-if="disease"
                :disease="disease"
                @open="onOpen"
                @createSales="createSalesPopup = true"
                @createManager="createManagerPopup = true"
            />
        </section>
        <transition name="fade" ease="in-out">
            <CreateSales
                v-if="createSalesPopup"
                :disease="disease"
                :target="target"
                @close="onClose('sales')"
                @refresh="$refs.accountTable.getAllSales()"
            />
        </transition>
        <transition name="fade" ease="in-out">
            <CreateManager
                v-if="createManagerPopup"
                :disease="disease"
                :target="target"
                @close="onClose('manager')"
                @refresh="$refs.accountTable.getAllSales()"
            />
        </transition>
        <transition name="fade" ease="in-out">
            <ChangeAuth
                v-if="changeAuthPopup"
                :disease="disease"
                :target="target"
                @close="onClose('change')"
                @refresh="$refs.accountTable.getAllSales()"
            />
        </transition>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import AccountTable from '@/components/SuperConsole/AccountTable.vue';
import CreateSales from '@/components/SuperConsole/CreateSales.vue';
import CreateManager from '@/components/SuperConsole/CreateManager.vue';
import ChangeAuth from '@/components/SuperConsole/ChangeAuth.vue';

export default {
    mixins: [],

    components: {
        AccountTable,
        CreateSales,
        CreateManager,
        ChangeAuth,
    },
    data() {
        return {
            // popup status
            createSalesPopup: false,
            createManagerPopup: false,
            changeAuthPopup: false,

            // prop
            target: undefined,

            // data
            disease: undefined,
            diseaseList: [],
        };
    },
    created() {
        this.setDiseaseList();
    },
    mounted() {},
    methods: {
        ...mapActions({
            apiClient: 'api/invoke',
        }),
        async setDiseaseList() {
            const PAYLOAD = {
                category: 'Disease',
                function: 'list',
            };
            const RES = await this.apiClient(PAYLOAD);
            this.diseaseList = RES.data.filter((item) => item.id !== 'super');
            const FIRST_INDEX = 0;
            this.disease = this.diseaseList[FIRST_INDEX].id;
        },
        onClose(auth) {
            if (auth === 'sales') this.createSalesPopup = false;
            else if (auth === 'manager') this.createManagerPopup = false;
            else this.changeAuthPopup = false;
            this.target = undefined;
        },
        onOpen(target, auth) {
            this.target = target;
            if (auth === 'sales') this.createSalesPopup = true;
            else if (auth === 'manager') this.createManagerPopup = true;
            else this.changeAuthPopup = true;
        },
    },
};
</script>

<style lang="scss" >
.super-console {
    &:first-child {
        margin-top: 50px;
    }
    > section {
        width: 90%;
        margin: 0 auto 40px;
        > .label {
            width: 200px;
        }
    }
    .select-disease {
        display: flex;
        align-items: center;
        .input {
            width: 250px;
        }
    }
}
</style>
