<template>
    <pop-up background-color="rgba(0, 0, 0, 0.7)" :on-close="onCancel">
        <div class="text">
            <div class="title">管理者資料</div>
        </div>
        <el-form
            :model="ruleForm"
            :rules="rules"
            ref="form"
            class="form"
            inline
        >
            <div class="input-block">
                <div class="label">姓名</div>
                <el-form-item fluid class="input" prop="name">
                    <el-input
                        style="width: 100%"
                        v-model="ruleForm.name"
                        placeholder="請輸入姓名"
                    ></el-input>
                </el-form-item>
            </div>
            <div class="input-block">
                <div class="label">登入信箱</div>
                <el-form-item fluid class="input" prop="email">
                    <el-input
                        style="width: 100%"
                        v-model="ruleForm.email"
                        placeholder="請輸入信箱"
                    ></el-input>
                </el-form-item>
            </div>
            <div class="input-block">
                <div class="label">密碼</div>
                <el-form-item fluid class="input" prop="password">
                    <el-input
                        style="width: 100%"
                        v-model="ruleForm.password"
                        placeholder="請輸入密碼"
                    ></el-input>
                </el-form-item>
            </div>
        </el-form>
        <div class="button-set" :class="{ 'space-between': isEditMode }">
            <div v-if="isEditMode" class="button-set">
                <button-wrapper
                    type="modal"
                    color="normal"
                    :width="130"
                    @click="onRenewToken"
                >
                    renew-token
                </button-wrapper>
                <button-wrapper
                    type="modal"
                    color="normal"
                    @click="onDeactivate"
                >
                    停用
                </button-wrapper>
            </div>
            <button-wrapper
                v-if="isEditMode"
                type="modal"
                color="primary"
                @click="onSave"
            >
                儲存
            </button-wrapper>
            <button-wrapper
                v-else
                type="modal"
                color="primary"
                @click="onConfirm"
            >
                確定
            </button-wrapper>
        </div>
    </pop-up>
</template>

<script>
import PopUp from '@/components/Popup/PopUpWrapper.vue';
import { mapActions } from 'vuex';

const validateEmail = (rule, value, callback) => {
    const REG = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
    if (!value.match(REG)) {
        callback(new Error('請輸入正確格式'));
    } else callback();
};

export default {
    mixins: [],
    props: {
        disease: String,
        target: Object,
    },
    components: { PopUp },
    data() {
        return {
            ruleForm: {
                name: '',
                email: '',
                password: '',
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '此欄位必填',
                        trigger: 'blur',
                    },
                ],
                email: [
                    {
                        required: true,
                        message: '此欄位必填',
                        trigger: 'blur',
                    },
                    { validator: validateEmail },
                ],
                password: [
                    {
                        required: true,
                        message: '此欄位必填',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    computed: {
        isEditMode() {
            return !!this.target;
        },
    },
    created() {
        this.fetchData();
    },
    mounted() {},
    methods: {
        ...mapActions({
            apiClient: 'api/invoke',
        }),
        fetchData() {
            if (!this.isEditMode) return;
            this.ruleForm.name = this.target.name;
            this.ruleForm.email = this.target.email;
        },
        async onConfirm() {
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    const SUBMIT = this.ruleForm;
                    const PAYLOAD = {
                        category: 'Account',
                        function: 'superCreateManager',
                        successMsg: '成功創立管理者',
                        data: { disease: this.disease, submit: SUBMIT },
                    };
                    await this.apiClient(PAYLOAD);
                    this.$emit('close');
                    this.$emit('refresh');
                    return true;
                }
                return false;
            });
        },
        async onSave() {
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    const PAYLOAD = {
                        category: 'Account',
                        function: 'superUpdateAccount',
                        successMsg: '成功修改管理者',
                        data: {
                            disease: this.disease,
                            id: this.target.id,
                            submit: this.ruleForm,
                        },
                    };
                    await this.apiClient(PAYLOAD);
                    this.$emit('close');
                    this.$emit('refresh');
                    return true;
                }
                return false;
            });
        },
        onCancel() {
            this.$emit('close');
        },
        async onRenewToken() {
            const PAYLOAD = {
                category: 'Account',
                function: 'superRenewToken',
                successMsg: '管理者已Renew Token',
                data: { disease: this.disease, ids: [this.target.id] },
            };
            await this.apiClient(PAYLOAD);
        },
        async onDeactivate() {
            const PAYLOAD = {
                category: 'Account',
                function: 'superDeactivate',
                successMsg: '管理者已停用',
                data: { disease: this.disease, ids: [this.target.id] },
            };
            await this.apiClient(PAYLOAD);
            this.$emit('close');
            this.$emit('refresh');
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    color: #000000;
    font-size: 24px;
    line-height: 35px;
}
.form {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .input-block {
        margin-top: 20px;
        height: 42px;
        display: flex;
    }
    .label {
        width: 72px;
        margin-right: 20px;
    }
    .input {
        width: calc(100% - 100px);
        margin-right: 0;
    }
    .el-form-item__label,
    .el-form-item__content {
        font-size: 18px !important;
    }
    .el-form--inline .el-form-item {
        margin-right: 0;
    }
}
.button-set.space-between {
    margin-top: 30px;
    justify-content: space-between;
}
</style>
